<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">

    <BAlert variant="danger" dismissible :show="machineId===null">
      탱크에 등록된 장비가 없습니다
    </BAlert>

    <BRow class="mb-3" v-if="machineId===null && onlyMachine===false">
      <BCol>
        <BInputGroup size="sm">
          <BFormSelect size="sm"
                       id="machines"
                       v-model="selectedMachineId"
                       @input="getMachineInfo(true)"
                       :options="machineOpts"
          />
          <BButton size="sm" variant="primary"
                   @click="linkMachine"
                   :disabled="selectedMachineId===null">
            장비 등록
          </BButton>
        </BInputGroup>

      </BCol>
    </BRow>



    <div v-if="machine!==null">
    <!-- 장비정보 입력폼 --------------------------------------------------------------------------------------------->
      <BRow class="mb-1">
        <BCol>
          <BRow>
            <BCol>
              <BInputGroup size="sm" prepend="장비 ID">
                <BFormInput size="sm"
                            class="bg-warning"
                            id="mid"
                            type="text"
                            :state="isValidExp(machine.mid, 'hex', 4)"
                            v-model="machine.mid"
                            maxLength="4"
                            @input="v=>(machine.mid = v.toUpperCase())"
                            required/>


                <BButtonGroup class="ml-1" v-if="machine.tank">
                  <BButton size="sm" variant="outline-danger" disabled>
                    {{ machine.tank.name }}
                  </BButton>
                  <BButton size="sm" variant="outline-danger" disabled>
                    {{ machine.tank.tankCode }}
                  </BButton>
                </BButtonGroup>

                <BButton size="sm"
                         v-show="machineId!==null && tankId!==null"
                         variant="primary" class="ml-2" @click="unlinkMachine">장비 해제</BButton>
              </BInputGroup>

              <BTooltip target="mid"
                        variant="danger"
                        :show="!(isValidExp(machine.mid, 'hex')&&machine.mid.length===4)"
                        placement="right" triggers="manual">
                16진수 4자 입력
              </BTooltip>
            </BCol>

          </BRow>

        </BCol>
      </BRow>

      <BRow class="mb-1">
        <BCol>
          <BRow>
            <BCol>
              <BInputGroup size="sm" prepend="장비명">
                <BFormInput size="sm" id="mName" type="text"
                            v-model="machine.name"
                            maxLength="100"
                            :state="(machine.name.length>0)"/>
                <BTooltip target="mName"
                          variant="danger"
                          :show="machine.name.length===0"
                          placement="right" :triggers="['manual']">
                  탱크이름 필수 입력
                </BTooltip>
              </BInputGroup>
            </BCol>
            <BCol>
              <BInputGroup size="sm" prepend="장비종류">
                <BFormSelect size="sm"
                             id="mType"
                             :state="machine.type!==''"
                             v-model="machine.type"
                             :options="opts['MACHINE_TYPE']"
                             required/>
                <BTooltip target="mType"
                          variant="danger"
                          :show="machine.type.length===0"
                          placement="top" :triggers="['manual']">
                  장비종류 필수 입력
                </BTooltip>
              </BInputGroup>
            </BCol>
          </BRow>
        </BCol>
      </BRow>

      <BRow class="mb-1">
        <BCol>
            <BInputGroup size="sm" prepend="일련번호">
              <BFormInput size="sm" id="serialNo" type="text" maxLength="50"
                          v-model="machine.serialNo"/>


              <BInputGroupAppend size="sm" is-text>장비버전</BInputGroupAppend>

              <BFormInput size="sm" id="version" type="text" maxLength="50"
                          v-model="machine.version"/>


            </BInputGroup>
        </BCol>
      </BRow>

      <BRow class="mb-2">
        <BCol>
          <BInputGroup size="sm" prepend="제품명">
            <BFormInput size="sm" id="productName" type="text" maxLength="50"
                        v-model="machine.productName"/>

            <BInputGroupAppend size="sm" is-text>모델명</BInputGroupAppend>
            <BFormInput size="sm" id="modelName" type="text" maxLength="50"
                        v-model="machine.modelName"/>

            <BInputGroupAppend size="sm" is-text>제조사</BInputGroupAppend>
            <BFormInput size="sm" id="company" type="text" maxLength="50"
                        v-model="machine.company"/>
          </BInputGroup>
        </BCol>
      </BRow>

      <BCard header="장비 연결정보" class="mb-1 m-1 p-1" no-body>
        <BRow class="mb-1">
          <BCol>
            <BInputGroup size="sm" prepend="연결장비">
              <BFormSelect size="sm"
                           id="linkMid"
                           :state="machine.linkMid!==''"
                           v-model="machine.linkMid"
                           :options="linkMidOpts"
                           required/>
            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup size="sm" prepend="연결형태">
              <BFormSelect size="sm"
                           id="linkType"
                           :state="machine.linkType!==''"
                           v-model="machine.linkType"
                           :options="opts['DATALINK_TYPE']"
                           required/>

            </BInputGroup>
          </BCol>
        </BRow>

        <BRow>
          <BCol>
            <BInputGroup size="sm" prepend="연결장비타입">
              <BFormSelect size="sm"
                           id="linkMchType"
                           :state="machine.linkMchType!==''"
                           v-model="machine.linkMchType"
                           :options="opts['MACHINE_TYPE']"
                           required/>
            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup size="sm" prepend="패킷종류">
              <BFormSelect size="sm"
                           id="packetType"
                           :state="machine.packetType!==''"
                           v-model="machine.packetType"
                           :options="opts['PACKET_TYPE']"
                           required/>
            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup size="sm" prepend="장비IP">
              <BFormInput size="sm" id="deviceIp" type="text" maxLength="50"
                          v-model="machine.deviceIp"/>

            </BInputGroup>
          </BCol>
        </BRow>

      </BCard>



      <BCard header="센서정보" class="m-1 p-1" no-body>
        <BRow class="mt-1">
          <BCol>
            <BInputGroup size="sm" prepend="센서타입">
              <BFormSelect size="sm"
                           id="sensorType"
                           :state="machine.sensorType!==''"
                           v-model="machine.sensorType"
                           :options="opts['SENSOR_TYPE']"
                           required/>

            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup prepend="센서길이(㎜)" size="sm">
              <BFormInput size="sm" id="sensorLen" type="number"
                          v-model="machine.sensorLen"
                          :step="1" min="1" max="15000"
                          :state="machine.sensorLen>0"/>
            </BInputGroup>

            <BTooltip target="sensorLen"
                      variant="danger"
                      :show="!machine.sensorLen>0"
                      placement="top" :triggers="['manual']">
              센서 길이(mm) 입력
            </BTooltip>
          </BCol>
        </BRow>

        <BRow class="mt-1">
          <BCol>
            <BInputGroup prepend="센서크기" size="sm" >
              <BFormSelect size="sm"
                           id="pipeType"
                           :state="machine.pipeType!==''"
                           v-model="machine.pipeType"
                           :options="opts['PIPE_TYPE']"
                           required/>

            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup prepend="체결길이(㎜)" size="sm">
              <BFormInput size="sm" id="joinLength" type="number"
                          v-model="machine.joinLength"
                          :step="1" min="1" max="15000"
                          :state="machine.joinLength>0"/>
            </BInputGroup>
          </BCol>
        </BRow>

        <BRow class="mt-1">
          <BCol>
            <BInputGroup prepend="Gain 값" size="sm" >
              <BFormInput size="sm" id="gainValue" type="number"
                          v-model="machine.gainValue"
                          :step="0.001" min="0.001" max="0.999"
                          :state="machine.gainValue>0"/>
            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup prepend="수분플롯(㎜)" size="sm">
              <BFormInput size="sm" id="sensorWater" type="number"
                          v-model="machine.sensorWater"
                          :step="1" :min="1" :max="500"
                          :state="machine.sensorWater>0"/>
            </BInputGroup>
          </BCol>
        </BRow>
      </BCard>

      <BCard class="mb-1">
        <template #header>
          플로우트 보정
        </template>

        <BRow class="mt-1">
          <BCol>
            <BInputGroup prepend="오일(mm)" size="sm" >
              <BFormInput size="sm" id="oilRevBase" type="number"
                          v-model="machine.oilRevBase"
                          :step="1" min="0" max="9999"
              />
            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup prepend="수분(mm)" size="sm" >
              <BFormInput size="sm" id="wtrRevBase" type="number"
                          v-model="machine.wtrRevBase"
                          :step="1" min="0" max="9999"
              />
            </BInputGroup>
          </BCol>
        </BRow>

      </BCard>


      <BCard class="mb-1">
        <template #header>
          온도센서 및 보정 설정
          <div class="card-header-actions">
            <BInputGroup prepend="센서갯수" size="sm">
            <BFormInput size="sm" id="numOfTemp" type="number" class="mb-0"
                        v-model="machine.numOfTemp"
                        step="1" :min="machine.temps.length" max="100"
                        @input="setTemps"
                        :state="machine.numOfTemp>0"/>
            </BInputGroup>
          </div>
        </template>

        <BTableSimple small class="mb-1">
          <BTr class="text-center">
            <BTh>번호</BTh><BTh>위치</BTh><BTh>보정</BTh><BTh>상태</BTh><BTh>삭제</BTh>
          </BTr>
          <BTr v-for="t in machine.temps" :key="t.idx">
            <BTd class="text-center">{{ t.idx }}</BTd>
            <BTd>
              <BFormInput v-model="t.location" size="sm" type="number" step="1" min="1" max="150000" class="small"/>
            </BTd>
            <BTd class="text-right">
              <BFormInput v-model="t.rev" size="sm" type="number" step="0.1" min="0" max="100" class="small"/>
            </BTd>
            <BTd class="text-center">{{ t.status }}</BTd>
            <BTd class="text-center">
              <BButton size="sm" variant="danger" @click="setTempsDel(t.idx)"><BIconTrashFill/></BButton>
            </BTd>
          </BTr>
        </BTableSimple>
        <BInputGroup prepend="유효 온도계 보정높이(mm)"
                     size="sm">
          <BFormInput size="sm" id="tempSinkRev" type="number" class="mb-0"
                      v-model="machine.tempSinkRev"
                      step="1" :min="0" max="1000"
                      :state="machine.tempSinkRev > 0"/>
        </BInputGroup>
        <span class="small text-info float-right">
            유위보다 <strong class="text-danger">{{machine.tempSinkRev}} mm</strong> 이하로 잠긴 온도센서를 유효 온도로 간주합니다.
        </span>

      </BCard>

      <BCard class="mb-1">
        <template #header>
          구간 보정 설정
          <div class="card-header-actions">
            <BInputGroup prepend="구간보정 수" size="sm">
              <BFormInput size="sm" id="numOfTemp" type="number" class="mb-0"
                          v-model="machine.numOfRev"
                          step="1" :min="machine.revisions.length" max="100"
                          @input="setRevs"
                          />
            </BInputGroup>
          </div>
        </template>

        <BTableSimple small class="mb-0">
          <BTr class="text-center">
            <BTh>번호</BTh><BTh>시작위치</BTh><BTh>종료위치</BTh><BTh>보정값(㎜)</BTh><BTh>삭제</BTh>
          </BTr>
          <BTr v-for="r in machine.revisions" :key="r.idx">
            <BTd class="text-center">{{ r.idx }}</BTd>
            <BTd>
              <BFormInput v-model="r.from" size="sm" type="number" step="1" min="1" max="150000" class="small"/>
            </BTd>
            <BTd>
              <BFormInput v-model="r.to" size="sm" type="number" step="1" min="1" max="150000" class="small"/>
            </BTd>
            <BTd class="text-right">
              <BFormInput v-model="r.rev" size="sm" type="number" step="1" min="-1000" max="1100" class="small"/>
            </BTd>
            <BTd class="text-center">
              <BButton size="sm" variant="danger" @click="setRevsDel(r.idx)"><BIconTrashFill/></BButton>
            </BTd>
          </BTr>
        </BTableSimple>
      </BCard>

      <BCard class="m-0">
        <template #header>
          암호키 설정
          <div class="card-header-actions small">
            <BBadge :variant="machine.keyIssAt?'info':'dark'">갱신</BBadge> {{toLocalYmd(machine.keyIssAt)}}
            <BBadge class="ml-1" :variant="machine.keyExpAt?'info':'dark'">추출</BBadge> {{toLocalYmd(machine.keyExpAt)}}
            <BBadge class="ml-1" :variant="machine.keyAppAt?'info':'dark'">적용</BBadge> {{toLocalYmd(machine.keyAppAt)}}
            <BBadge class="ml-1" :variant="machine.keyEndAt?'info':'dark'">만료</BBadge> {{toLocalYmd(machine.keyEndAt)}}
          </div>
        </template>
        <BRow class="mt-1">
          <BCol>
            <BInputGroup size="sm" prepend="단말 EUI">
              <BFormInput size="sm"
                          id="devId"
                          :state="isValidExp(machine.linkDevId, 'hex', 16)"
                          maxLength="16"
                          v-model="machine.linkDevId"
                          required/>
              <BInputGroupAppend>
                <BButton variant="warning" size="sm" @click='setLinkDevId'><BIconSave/> 저장</BButton>
              </BInputGroupAppend>
            </BInputGroup>
          </BCol>
        </BRow>
        <BRow class="mt-1" v-if="false">
          <BCol>
            <BInputGroup size="sm" prepend="MK KEK">
              <BFormInput size="sm"
                          id="keKey"
                          type="password"
                          v-model="machine.keKey"
                          readonly/>
            </BInputGroup>
          </BCol>
        </BRow>
        <BRow class="mt-1">
          <BCol>
            <BInputGroup size="sm" prepend="초기 벡터">
              <BFormInput size="sm"
                          id="devIv"
                          type="password"
                          v-model="machine.devIv"
                          maxLength="32"
                          readonly
                          />
            </BInputGroup>
          </BCol>
        </BRow>

        <BRow class="mt-1">
          <BCol class="text-right">
            <BInputGroup size="sm" prepend="단말기 키">
              <BFormInput size="sm"
                          id="plainKey"
                          type="password"
                          v-model="machine.devKey"
                          maxLength="64"
                          readonly
                          />

            </BInputGroup>
          </BCol>
        </BRow>
        <BRow class="mt-1" v-if="machine.keyEndAt">
          <BCol class="text-right">
            <BInputGroup size="sm" prepend="키 만료일">
              <BFormInput size="sm"
                          id="keyEndAt"
                          type="date"
                          v-model="newKeyEndAt"
              />
              <BInputGroupAppend>
                <BButton variant="warning" size="sm" @click='setKeyEndAt'><BIconSave/> 저장</BButton>
              </BInputGroupAppend>
            </BInputGroup>
          </BCol>
        </BRow>

        <BRow class="mt-2" v-if="machine">
          <BCol class="text-right">
            <BButtonGroup size="sm">
              <BButton variant="outline-danger" v-show="isNeedKeyRefresh" class="mr-3"> <BIconExclamationDiamondFill/> 키 갱신 필요</BButton>
              <BButton variant="warning" class="mr-1 font-weight-bold"  @click='setDevKey'><BIconArrowRepeat/> 키 갱신</BButton>
              <BButton variant="primary" class="mr-1 font-weight-bold" @click='exportKeyModal' :disabled="!machine.devKey || isUpdatingRecord"><BIconArrowDown/> 키 추출</BButton>
              <BButton variant="danger" class="mr-1 font-weight-bold"  @click='applyKey' :disabled="!machine.devKey || isUpdatingRecord">
                <BIconArrowRepeat/> 적용
                <div class="sk-swing float-right" style="height:25px;width:25px" v-if="isUpdatingRecord" >
                  <div class="sk-swing-dot"></div>
                </div>
              </BButton>
            </BButtonGroup>
          </BCol>
        </BRow>


      </BCard>


      <BRow class="mb-3">
        <BCol>
          <BBadge pill variant="info">등록</BBadge><span class="text-muted small"> {{`${toLocalTime(machine.createdAt)} | ${machine.regId?machine.regId:'NOBODY'}`}} </span>
        </BCol>
        <BCol class="text-right">
          <BBadge pill variant="primary">수정</BBadge><span class="text-muted small"> {{`${toLocalTime(machine.updatedAt)} | ${machine.updId?machine.updId:'NOBODY'}`}} </span>
        </BCol>
      </BRow>



      <BRow>
        <BCol>
          <b-button class="mt-1" v-b-toggle.row-machine size="sm">원시 데이터 보기</b-button>
        </BCol>
        <BCol>
          <BButton block variant="danger" @click="saveMachine" :disabled="isUpdatingRecord">
            <BIconSave2Fill/> <strong>장비정보 저장</strong>
            <div class="sk-plane bg-light float-left" style="height:25px;width:25px" v-if="isUpdatingRecord"></div>
          </BButton>

        </BCol>
      </BRow>


      <b-collapse id="row-machine" class="mt-2">
        <BTable small class="small" stacked :items="[machine]" :fields="machineFields">
          <template #cell(rowData)="data">
            <pre>{{data.item}}</pre>
          </template>
        </BTable>
      </b-collapse>

      <CModal ref="pwd-input-modal"
              color="danger"
              :centered="true"
              title="[키 추출] 계정 비밀번호 입력"
              :show.sync="exportKeyShow">
        <BInputGroup>
         <BFormInput v-model="userPwd" id="userPwd" type="password" debounce="6000"></BFormInput>
         <BButton variant="outline-danger" @click="exportKey"><BIconDownload/> 키 추출</BButton>
        </BInputGroup>
      </CModal>




    </div>

  </div>
</template>

<style src="spinkit/spinkit.min.css"></style>

<script>
//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  cloneVar, sleep,
} from '@/common/utils';
import moment from "moment";
// import {MachineSchema} from "@/common/schema";


//----------------------------------------------------------------------------------------------------
export default {
  name: "MachineForm",
  components: {},
  props: {
    machineId: { type: String, default: null },
    tankId: { type: String, default: null },
    onlyMachine: { type: Boolean, default: false },
  },
  watch:{
    machineId: function(newVal, oldVal){
      console.log('[props] machineId changed: ', newVal, ' | was: ', oldVal);
    }
  },

  data () {
    return {

      maps: this.$store.state.codeMaps,
      opts: this.$store.state.codeOpts,
      machine: null,
      pickerShow: false,
      machineOpts: [],
      selectedMachineId: null,

      originMachine: null,

      arCodeOpts  : [],
      linkMidOpts : [],

      machineFields: [
        {key: 'rowData', label: ''}
      ],

      isUpdatingRecord: false,
      exportKeyShow: false,
      userPwd: '',
      newKeyEndAt: null,
      isNeedKeyRefresh: false,
    }

  },
  async created(){
    console.log("--- MachineForm --- created---------------------");
    try{
      if(this.machineId) await this.getMachineInfo();
      else await this.getMachineOpts();

    }catch(err){
      console.error(err);
    }
  },
  computed: {},

  mounted() {
    // console.log("--- MachineForm --- mounted---------------------");
    // below is not work!
  },

  methods: {
    setFromDate(){
      this.toDate =  moment(this.fromDate).add(1,"month").subtract(1,'day').format("YYYY-MM-DD");
    },

    setTemps(){
      let toCnt = this.machine.numOfTemp;
      if(!toCnt) return;

      let temps = [];
      if( !this.machine.temps ) this.machine.temps = [];

      // console.log( 'npmOfTemp ----> ', toCnt );
      let idx = 1;

      for( let t of this.machine.temps ){
        temps.push( {idx: idx, location: t.location, rev: t.rev, status: t.status } );
        idx++;
      }


      for(let i=idx; i<=toCnt; i++){
        // console.log( 'i-->', i );
        temps.push( {idx: i, location: 0, rev: 0, status: 'NA' });
      }

      this.machine.temps = temps;
      // console.log( 'temps array ------> ', this.machine.temps );

    },

    setTempsDel( idx ){
        this.machine.temps = this.machine.temps.filter(i=> {
          return i.idx !== idx
        });
        this.machine.numOfTemp = this.machine.temps.length;
        this.setTemps();
    },


    setRevs(){
      let toCnt = this.machine.numOfRev;
      if(!toCnt) return;

      let revs = [];
      if( !this.machine.revisions ) this.machine.revisions = [];
      // console.log( 'npmOfTemp ----> ', toCnt );

      let idx = 0;
      for( let r of this.machine.revisions ){
        revs.push( {idx: idx, from: r.from, to: r.to, rev: r.rev } );
        idx++;
      }

      for(let i=idx; i<toCnt; i++){
        // console.log( 'i-->', i );
        revs.push( {idx: i, from: 0, to:0, rev: 0});
      }
      this.machine.revisions = revs;
      // console.log( 'revisions array ------> ', this.machine.revisions );

    },

    setRevsDel( idx ){
      this.machine.revisions = this.machine.revisions.filter(i=> {
        return i.idx !== idx
      });
      this.machine.numOfRev = this.machine.revisions.length;
      this.setRevs();
    },


    async getMachineInfo(select=false){
      try{
        let objectId;
        if(select) objectId = this.selectedMachineId;
        else objectId = this.machineId;

        if(!objectId) {
          this.machine = null;
          this.originMachine = null;
          return
        }

        // console.log("getMachineData machine._id --------->", objectId);
        const r = await apiCall('get', `/api/machine/${objectId}`);
        if(r.code!==200){
          return await this.alertDanger('NOT_FOUND: '+objectId )
        }

        if(select){
          // this.selectedMachineId = r.result;
          this.machine = r.result;
        }else{
          this.machine = r.result;
          this.originMachine = cloneVar(r.result);
        }

        // console.log("\n\n\n\n--------------machine---------------\n", this.machine);
        this.newKeyEndAt = this.machine.keyEndAt? this.toLocalYmd(this.machine.keyEndAt): null;
        this.isNeedKeyRefresh = this.machine?.keyEndAt && ( moment().unix() > moment(this.machine.keyEndAt).unix() )
        // console.log( 'getTankInfo result ---> select---', select, '---info---',r.result );

      }catch(err){
        console.error("getTaskData error", err);
      }
    },

    async getMachineOpts(){
      // console.log("getMachineOptions---------");
      const {result} = await apiCall('get', `/api/machine`);
      if( result ){
        this.machineOpts = [ {text: '장비 선택', value: null} ];
        for(let m of result){
          // console.log( 'machine ---> ', m );
          if(m.tank===null)
            this.machineOpts.push({text:`[${m.mid}] ${m.name} / ${m.modelName}`, value:m._id} );
        }
        this.selectedMachineId = null;
        // console.log( 'machine options --->', this.machineOpts);
      }

      // console.log( 'getTankInfo result --->', result );
    },

    async linkMachine(){
      try {
        if (!await this.confirmModal(`장비 '${this.machine.mid}'를 탱크에 등록합니다. 진행하시겠습니까?`, '탱크장비 등록')) {
          return await this.alertModal('장비 등록이 취소되었습니다', '장비정보', 'warning');
        }

        const tankParam = {
          machine: this.machine._id,
          mid: this.machine.mid
        }

        const rs = await apiCall('put', `/api/tank/link-machine/${this.tankId}`, tankParam);
        // console.log('linkMachine result ---------------tank---->',rs);
        if (rs.code === 200) {
          this.$emit('update:machineId', this.machine._id);
          // this.machineId = this.machine._id
          // console.log('linkMachine this.machineId---->',this.machineId)
          this.machine = rs.result.machine;
          this.machine.tank = rs.result.tank;
        } else {
          await this.alertDanger('장비 연결 실패: ' + rs.message);
        }
      }catch(err){
        console.error(err);
      }
    },

    async unlinkMachine(){
      try{
        if (!await this.confirmModal(`장비 '${this.machine.mid}'를 해제합니다. 진행하시겠습니까?`, '탱크 장비 해제')) {
          return await this.alertModal('탱크장비 해제가 취소되었습니다', '장비정보', 'warning');
        }

        const param = {
          machine: this.machine._id,
        }

        const r = await apiCall('put', `/api/tank/unlink-machine/${this.tankId}`, param);
        // console.log('unlinkMachine result ---------------tank---->',r);
        if (r.code === 200) {
          this.machine = null;
          this.$emit('update:machineId', null);
          await this.getMachineOpts();
        } else {
          await this.alertDanger('장비 연결 해제 실패: ' + r.message);
        }
      }catch(err){
        console.error(err);
      }

    },


    async saveMachine(){
      let rs = { code: 999, message: '', result: false };
      this.isUpdatingRecord = true;
      try{
        // const row = this.$refs['excelGrid'].currentRecord;
        const rec = this.machine;
        // console.log( 'param --->', rec);
        rs = await apiCall("PUT", `/api/machine/${rec._id}`, rec);
        // console.log( 'result ----------->', rs );
        // this.machine = rs.result;

        await sleep(1000);

        if(rs.code===200){
          await this.toastInfo('장비정보 수정 성공');
        }else{
          await this.toastResult(rs, `장비정보 수정`);
        }

      }catch(err){
        console.error(err);
        await this.alertDanger( err.message, rs.code, 'danger');
      }finally{
        this.isUpdatingRecord = false;
      }
    },


    async setLinkDevId(){
      try{
        if( !this.isValidExp(this.machine.linkDevId, 'hex', 16) ){
          return alert('EUI는 16진수 16자(8 bytes)를 입력해야 합니다.');
        }

        const param = {
          linkDevId: this.machine.linkDevId.toUpperCase(),
        };

        const rs = await apiCall("PUT", `/api/machine/dev-id/${this.machine._id}`, param);

        await this.toastResult(rs, `단말 EUI (Unique ID) 정보저장`);
        if(rs.code===200){
          this.machine.linkDevId = rs.result.linkDevId;
        }
      }catch(err){
        console.error( err );
        await this.alertDanger(err.message, 'ERROR', 'danger');
      }
    },


    async setDevKey(){
      try{
        if(!this.machine.linkDevId) {
          return await this.alertDanger('단말 EUI는 필수 입력 값 입니다', '단말 EUI' );
        }
        const param = {
          linkDevId: this.machine.linkDevId.toUpperCase(),
        };

        const rs = await apiCall("PUT", `/api/machine/dev-key/${this.machine._id}`, param);

        await this.toastResult(rs, `장비 암호키 갱신`);

        if(rs.code===200){
          this.machine.keKey = rs.result.kek1;
          this.machine.keIv = rs.result.keIv;
          this.machine.devIv = rs.result.devIv;
          this.machine.devKey = rs.result.devKey;
          this.machine.keyIssId = rs.result.keyIssId;
          this.machine.keyIssAt = rs.result.keyIssAt;
          this.machine.keyEndAt =  rs.result.keyEndAt;
          this.machine.keyExpAt =  rs.result.keyExpAt;
          this.machine.keyAppAt =  rs.result.keyAppAt;
          this.machine.isKeyApplied = rs.result.isKeyApplied;
          this.machine.isKeyExported = rs.result.isKeyExported;
          this.machine.keyIssid = rs.result.keyIssId;
        }

      }catch(err){
        console.error( err );
        await this.alertDanger( err.message, 'ERROR', 'danger' );
      }
    },

    exportKeyModal(){
      this.exportKeyShow=true;
      this.userPwd=''
    },

    async exportKey(){
      try{
        const m = this.machine;
        if( !(m.devKey && m.devIv && m.keIv && m.keKey) ){
          return await this.alertDanger('키 갱신이 필요합니다','키 추출');
        }

        const param = {
          linkDevId: this.machine.linkDevId.toUpperCase(),
          userPwd: this.userPwd
        };

        const fileName = `${m.mid}-${m.linkDevId}-${moment().format('YYYYMMDD')}.key`;
        console.log('exportKey()---->', fileName);
        const rs = await apiCall('POSTFILE', `/api/machine/export-key/${this.machine._id}`, param);
        console.log( 'export key result --->', rs );
        await this.getDownload( fileName, rs );
        this.userPwd = '';
        this.exportKeyShow = false;
        this.machine.keyExpAt = moment().format('YYYY-MM-DD HH:mm');
        this.machine.isKeyExported = true;

      }catch(err){
        console.error( err );
        await this.alertDanger("키 추출 에러" );
      }
    },

    async applyKey(){
      try{
        if(!this.machine.devKey && !this.machine.devIv) {
          return await this.alertDanger('단말기 키 갱신이 필요합니다','키 없음');
        }
        if(!this.machine.isKeyExported){
          if (!await this.confirmModal(`장비 키가 추출되지 않았습니다. 키 미반영시 신호유입이 중단됩니다.`, '장비 키적용')) {
            return null;
          }
        }

        this.isUpdatingRecord = true;

        const param = {
          linkDevId: this.machine.linkDevId.toUpperCase(),
        };

        const rs = await apiCall("PUT", `/api/machine/apply-key/${this.machine._id}`, param);
        // console.log( 'result ----------->', rs );
        // this.machine = rs.result;

        await sleep(1000);
        await this.toastResult(rs, `장비 키 적용`);
        if(rs.code===200){
          this.machine.keyAppAt = rs.result.keyAppAt;
          this.machine.keyEndAt = rs.result.keyEndAt;
          this.newKeyEndAt = this.toLocalYmd( this.machine.keyEndAt );
          this.machine.isKeyApplied = rs.result.isKeyApplied;
        }

      }catch(err){
        console.error( err );
        await this.alertDanger('키 파일 추출 실패', 'ERROR', 'danger');
      }finally{
        this.isUpdatingRecord = false;
      }
    },


    async setKeyEndAt(){
      console.log( 'machine.keyEndAt --->', this.newKeyEndAt );
      try{

        this.isUpdatingRecord = true;

        const param = {
          linkDevId: this.machine.linkDevId.toUpperCase(),
          keyEndAt: this.newKeyEndAt
        };

        const rs = await apiCall("PUT", `/api/machine/key-end-at/${this.machine._id}`, param);
        console.log(rs);
        if(rs.code===200){
          this.machine.keyEndAt = rs.result.keyEndAt;
          this.newKeyEndAt = this.toLocalYmd( this.machine.keyEndAt );
          this.isNeedKeyRefresh = this.machine?.keyEndAt && ( moment().unix() > moment(this.machine.keyEndAt).unix() )
        }
        await this.toastResult(rs, `키 만료일 적용`);
      }catch(err){
        console.error( err );
        await this.alertDanger('키 만료일 적용 실패', '만료일 적용');
      }finally{
        this.isUpdatingRecord = false;
      }


    }



  }
}
</script>
